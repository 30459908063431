import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			IMGURL: process.env.VUE_APP_IMG_PREFIX,

			defaultPkg: 'com.qdrsd.boxassistand',
		}
	},
	computed: {
		...mapGetters(['findDict']),
		isIos() {
			const ua = navigator.userAgent
			const isIos = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
			const isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1 //android终端
			return isIos
		},
	},
	methods: {
		// 设置图片域名
		setImgPrefix(url) {
			if (!url) return ''

			return `${process.env.VUE_APP_IMG_PREFIX}${url}`
		},
		// 设置链接域名
		setUrlPrefix(url) {
			if (!url) return ''

			return `${process.env.VUE_APP_IMG_PREFIX}${url}`
		},
		// 可以传个数组，也可以传单个字符串
		// 传数组时，此方法没有返回值，会遍历数组掉接口获取字典
		// 传字符串时，返回值是type对应的字典
		/**
		 * 获取字典集合
		 * @param {string | array[string]} type 字典类型
		 * @returns {undefined | dict} undefined或type对应的字典
		 */
		async getDict(...args) {
			if (Array.isArray(args[0])) {
				for (let i = 0; i < args[0].length; i++) {
					try {
						const type = args[0][i]
						await this.$store.dispatch('dict/getDict', type)
					} catch {}
				}
				return
			}

			return this.$store.dispatch('dict/getDict', args[0])
		},
		numFormat(number, decimals, dec_point, thousands_sep) {
			/*
			 * 参数说明：
			 * number：要格式化的数字
			 * decimals：保留几位小数
			 * dec_point：小数点符号
			 * thousands_sep：千分位符号
			 * */
			//  使用： numFormat(1234567.089, 2, ".", ","); //1,234
			number = (number + '').replace(/[^0-9+-Ee.]/g, '')
			let n = !isFinite(+number) ? 0 : +number,
				prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
				sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
				dec = typeof dec_point === 'undefined' ? '.' : dec_point,
				s = '',
				toFixedFix = function (n, prec) {
					let k = Math.pow(10, prec)
					return '' + Math.floor(n * k) / k
				}
			s = (prec ? toFixedFix(n, prec) : '' + Math.floor(n)).split('.')
			let re = /(-?\d+)(\d{3})/
			while (re.test(s[0])) {
				s[0] = s[0].replace(re, '$1' + sep + '$2')
			}

			if ((s[1] || '').length < prec) {
				s[1] = s[1] || ''
				s[1] += new Array(prec - s[1].length + 1).join('0')
			}
			return s.join(dec)
		},

		dateFormat(date, fmt) {
			let ret
			const opt = {
				'Y+': date.getFullYear().toString(), // 年
				'M+': (date.getMonth() + 1).toString(), // 月
				'D+': date.getDate().toString(), // 日
				'h+': date.getHours().toString(), // 时
				'm+': date.getMinutes().toString(), // 分
				's+': date.getSeconds().toString(), // 秒
				// 有其他格式化字符需求可以继续添加，必须转化成字符串
			}
			for (let k in opt) {
				ret = new RegExp('(' + k + ')').exec(fmt)
				if (ret) {
					fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
				}
			}
			return fmt
		},

		// 平台判断
		isIOS() {
			const u = navigator.userAgent
			const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
			return isiOS
		},

		/**
		 * 字段脱敏处理
		 * @param {String} field 未脱敏字段
		 * @param {Int} before 开头未脱敏字符数
		 * @param {Int} after 结尾未脱敏字符数
		 * @return {String} 已脱敏字段
		 */
		sensitiveField(field, before = 3, after = 4, num = 5) {
			if (!field) {
				return ''
			}
			field = String(field)

			// 匹配中文、英文、数字
			const regItem = '[\u4e00-\u9fa5a-zA-Z0-9]'
			const regExp = `(${regItem}{${before}})${regItem}*(${regItem}{${after}})`
			const reg = new RegExp(regExp)

			let starStr = ''
			for (let i = 0; i < num; i++) {
				starStr += '*'
			}

			return field.replace(reg, `$1${starStr}$2`)
		},

		copyText(text, container) {
			// 数字没有 .length 不能执行selectText 需要转化成字符串
			const textString = text.toString()
			let wrapper = container || document.body
			let input = document.querySelector('#copy-input')

			if (!input) {
				input = document.createElement('textarea')
				input.id = 'copy-input'
				input.readOnly = 'readOnly' // 防止ios聚焦触发键盘事件
				input.style.position = 'absolute'
				input.style.left = '-1000px'
				input.style.zIndex = '-1000'
				wrapper.appendChild(input)
			}
			input.value = textString
			// ios必须先选中文字且不支持 input.select();
			selectText(input, 0, textString.length)
			//console.log(document.execCommand('copy'), 'execCommand')
			if (document.execCommand('copy')) {
				document.execCommand('copy')
				// console.log('已复制到粘贴板')
			}
			input.blur()

			// input自带的select()方法在苹果端无法进行选择，所以需要自己去写一个类似的方法
			// 选择文本。createTextRange(setSelectionRange)是input方法
			function selectText(textbox, startIndex, stopIndex) {
				if (textbox.createTextRange) {
					//ie
					const range = textbox.createTextRange()
					range.collapse(true)
					range.moveStart('character', startIndex) //起始光标
					range.moveEnd('character', stopIndex - startIndex) //结束光标
					range.select() //不兼容苹果
				} else {
					//firefox/chrome
					textbox.setSelectionRange(startIndex, stopIndex)
					textbox.focus()
				}
			}
		},
	},
}
