<template>
  <div>
    <van-nav-bar
      class="navbar"
      :class="classObj[type]"
      :title="title"
      :border="false"
      :fixed="true"
      left-text=""
      right-text=""
      :safe-area-inset-top="true"
      v-on="$listeners"
      v-bind="$attrs"
      @click-left="onClickLeft">
      <template #left>
        <img v-if="whiteArrowArr.indexOf(type) !== -1" class="arrow" src="@/assets/common/icon_arrow_left_white.svg" alt="">
        <img v-if="blackArrowArr.indexOf(type) !== -1" class="arrow" src="@/assets/common/icon_arrow_left_black.svg" alt="">
      </template>
      <template #title>
        <slot name="title" />
      </template>
      <template #right>
        <slot name="right" />
      </template>
    </van-nav-bar>
    <div class="placeholder"></div>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  data(){
    return {
      classObj: {
        '1': 'white-theme',
        '2': 'blue-theme',
        '3': 'gray-theme',
        '4': 'deep-blue-theme',
      },
      whiteArrowArr: ['2', '4'],
      blackArrowArr: ['1', '3'],
    }
  },
  props: {
      type: {
        type: String,
        default: '1'
      },
      title: {
          type: String,
          default: ''
      }
      // hasBorder: {
      //     type: Boolean,
      //     default: true
      // }
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    }
  }
}
</script>

<style lang="scss" scoped>
  .van-nav-bar {
    height: 44PX;
  }
  .arrow {
      width: 22PX;
      height: 22PX;
  }
  .navbar {
    padding-top: var(--status-bar-height);
    width: 100%;
    z-index: 2000;
  }
  .placeholder {
    padding-top: var(--status-bar-height);
    height: 44PX;
  }
  .white-theme {
    background-color: #fff;
    ::v-deep .van-nav-bar__title {
      color: #323233;
    }
  }
  .blue-theme {
    background-color: #2E5BFD;
    ::v-deep .van-nav-bar__title {
      color: #fff;
    }
  }
  .deep-blue-theme {
    background-color: #2948FF;
    ::v-deep .van-nav-bar__title {
      color: #fff;
    }
  }
  .gray-theme {
    background-color: #F3F5F9;
    ::v-deep .van-nav-bar__title {
      color: #323233;
    }
  }
</style>
