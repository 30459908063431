import request from '../request'

export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data
  })
}

export function logout() {
  return request({
    url: '/login/logout',
    method: 'post'
  })
}

// 修改手机号
export function login_update_phone(data) {
  return request({
    url: '/login/update/phone',
    method: 'post',
    data
  })
}

// 重置密码
export function home_account_safe_password_reset(data) {
  return request({
    url: '/home/account/safe/password/reset',
    method: 'post',
    data
  })
}

// 注册
export function login_registered(data) {
  return request({
    url: '/login/registered',
    method: 'post',
    data
  })
}

// 发送短信验证码
export function login_send_captcha(data) {
  return request({
    url: '/login/send/captcha',
    method: 'post',
    data
  })
}

// 根据推荐码获取用户信息
export function login_find_recCode(params){
  return request({
    url: '/login/find/recCode',
    method: 'get',
    params
  })
}

// 根据推荐码或手机号获取用户信息
export function login_find_recCodeOrMobile(params){
  return request({
    url: '/login/find/recCodeOrMobile',
    method: 'get',
    params
  })
}

// 找回密码
export function login_find_password(data){
  return request({
    url: '/login/find/password',
    method: 'post',
    data
  })
}


// 获取下载地址
export function get_downloadUrl(params) {
	return request({
		url: '/operate/version/info',
		method: 'get',
		params,
	})
}


// 协议获取
export function agreement_info(params) {
  return request({
    url: '/operate/agreement/info',
    method: 'get',
    params,
  })
}

// 获取下载链接
export function operate_download_url(params) {
	return request({
		url: '/operate/download/url',
		method: 'get',
		params,
	})
}

