<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
   mounted() {
    // 判断生产环境不显示VConsole
    // if (process.env.NODE_ENV !== `production`) {
    //   localStorage.setItem(`vConsole_switch_x`, 0);
    //   localStorage.setItem(`vConsole_switch_y`, 200);
    //   new VConsole();
    // }
  },
}
</script>

<style>
</style>
