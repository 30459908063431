import Vue from 'vue'
import Router from 'vue-router'
const Register = (resolve) => require(['@/views/login/register.vue'], resolve)
Vue.use(Router)
//按需加载,当渲染其他页面时才加载其组件,并缓存,减少首屏加载时间

const router = new Router({
	routes: [
		{
			path: '/',
			redirect: '/reg/register',
		},
		{
			path: '/reg',
			name: '注册',
			redirect: '/reg/register',
			component: {
				render() {
					return <router-view />
				},
			},
			children: [
				{
					path: 'register',
					name: 'register',
					component: () => import('@/views/login/register'),
				},
				{
					path: 'userAgreement',
					name: 'userAgreement',
					component: () => import('@/views/login/userAgreement'),
				},
				{
					path: 'secrecyAgreement',
					name: 'secrecyAgreement',
					component: () => import('@/views/login/secrecyAgreement'),
				},
				{
					path: 'test',
					name: 'test',
					component: () => import('@/views/login/test'),
				},
			],
		},
		{
			path: '/increase',
			name: '增值',
			redirect: '/increase/index',
			component: {
				render() {
					return <router-view />
				},
			},
			children: [
				{
					path: 'index',
					name: 'increase',
					component: () => import('@/views/increase'),
					meta: {
						title: '申请信息核实'
					}
				}
			],
		},
		{
			path: '/training',
			name: '训练营',
			redirect: '/training/index',
			component: {
				render() {
					return <router-view />
				},
			},
			children: [
				{
					path: 'index',
					name: 'inviteMemver',
					component: () => import('@/views/training'),
					meta: {
						title: '邀请成员'
					}
				},
			],
		},
	],
	scrollBehavior() {
		// scrollBehavior(to, from, savedPosition) {
		// console.log('跳转数据',to, from, savedPosition);
		return {
			x: 0,
			y: 0,
		}
	},
})

router.beforeEach((to, from, next) => {
	const title = to.meta.title

	if (title) {
		document.title = title
	}

	next()
})

export default router
