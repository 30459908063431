<template>
  <van-popup
    v-on="$listeners"
    v-bind="$attrs"
    :value="value"
    :round="true"
    :close-on-click-overlay="false"
  >
    <div class="dialog">
      <img class="prompt-bg" src="./images/prompt_bg.svg" alt="" />
      <div class="title">{{ title }}</div>
      <div class="desc">
        <slot>{{ desc || '' }}</slot>
      </div>
      <div class="btn-area">
        <div class="btn-group" v-if="oneBtn">
          <div class="btn-know" @click="$emit('input', false)">我知道了</div>
        </div>
        <div class="btn-group flex flex-middle-center" v-else>
          <div class="btn btn-cancel" @click="onCancel">
            {{ cancelBtnText }}
          </div>
          <div class="btn btn-sure" @click="$emit('confirm', true)">
            {{ confirmBtnText }}
          </div>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: "Prompt",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: '提示信息'
    },
    desc: {
      type: String,
      default: "",
    },
    oneBtn: {
      type: Boolean,
      default: false,
    },
    cancelBtnText: {
      type: String,
      default: '取消',
    },
    confirmBtnText: {
      type: String,
      default: '确定',
    }
  },
  methods:{
    onCancel(){
      this.$emit('input', false)
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss" scoped>
.van-popup {
  width: 250px;
  min-height: 190px;
}
.dialog {
  padding: 0 16px;
  box-sizing: border-box;
  .prompt-bg {
    width: 100%;
    height: 68px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .title {
    font-size: 16px;
    color: #313235;
    text-align: center;
    font-weight: 600;
    margin-top: 18px;
    position: relative;
    z-index: 9;
  }
  .desc {
    font-size: 14px;
    margin-top: 18px;
    text-align: center;
    padding-bottom: 70px;
    color: #5e6065;
    position: relative;
    z-index: 9;
  }
  .btn-area {
    width: 100%;
    height: 54px;
    position: absolute;
    left: 0;
    bottom: 8px;
    padding: 0 16px;
    box-sizing: border-box;
    z-index: 10;
  }
  .btn-group {
    padding-top: 8px;
    box-sizing: border-box;
    border-top: 1px solid #ecedf2;
    .btn {
      width: 101px;
      height: 38px;
      font-size: 14px;
      text-align: center;
      line-height: 38px;
      border-radius: 4px;
    }
    .btn-cancel {
      color: #313235;
      background-color: #f5f6fa;
    }
    .btn-sure {
      color: #fff;
      background-color: #2e5bfd;
    }

    .btn-know {
      width: 218px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      color: #fff;
      background-color: #2e5bfd;
      border-radius: 4px;
    }
  }
}
</style>
