import axios from 'axios'
import router from '../router'
import { Toast } from 'vant';

const request = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 0, //指定请求超时的毫秒数(0 表示无超时时间)
    headers: {
        // 'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Cache-Control': 'no-cache',
        // 'Access-Control-Expose-Headers': 'authToken'
    },
    showError: true,        //显示错误消息
    showLoading: true,      //显示loading
    requireLogin: true      //是否依赖登录
});

// 请求拦截
request.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if(userInfo){
      config.headers.authToken = userInfo.authToken;
    }

    return config;

  }, function (error) {
    // 对请求错误做些什么
    alert('网络错误,请稍后再试');


    return Promise.reject(error);
  });

// 添加响应拦截器
request.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    // 加到时器主要是为了 展示Loading效果 项目中应去除
    setTimeout(()=>{
    },300)

    // if(response.data.data.authToken){
    //   store.commit('CHANGE_TOKEN', response.data.data.authToken);
    // }

    return response;

  }, function (error) {
    // 对响应错误做点什么
    if(error.response) {
      const { status, data } = error.response;
      if(status== 401) {
          // 如果返回401 即没有权限，跳到登录页重新登录
        // store.commit('CHANGE_TOKEN',0);

        Toast(data.message);

      }

      Toast(data.message);
    }

    return Promise.reject(error);
  });
export default request
